.popper__main {
  background-color: var(--light-bg-color);
  border-radius: 4px;
  text-align: center;
  border: 1px solid var(--button-color);
  z-index: 50;
}

.popper__top {
  background-color: var(--very-light-bg-color);
  padding: 4px;
  display: flex;
  align-items: center;
  margin: 4px 0;
  z-index: 300;
}

.popper__main[hidden] {
  display: none;
}

.popper__info{
  background-color: var(--light-bg-color);
  border-radius: 4px;
  text-align: center;
  border: 1px solid var(--button-color);
  z-index: 50;
  padding: 5px;

}

.popper__info[hidden] {
  display: none;
}

.popper__img {
  height: 46px;
}

.popper__name {
  padding: 0 14px;
  font-size: 1.5rem;
}

.popper__folder {
  font-size: 0.9rem;
  padding-bottom: 4px;
}

.popper__linked-element {
  cursor: pointer;
  text-decoration: underline dotted var(--button-color) 1.5px;
  text-underline-offset: 1.5px;
}
