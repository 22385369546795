.DraftEditor-root {
  background-color: var(--light-bg-color);
  height: 128px;
  min-height: 128px;
  border-radius: 4px;
  padding: 4px;
  color: var(--text-color);
  line-height: 1.25;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2);
}

.generic__alert-text {
  color: var(--error-text-color);
  font-size: 0.75rem;
  line-height: 1rem;
  padding-top: 4px;
  padding-left: 4px;
}

.generic__buttons {
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px;
  color: white;
  background-color: var(--button-color);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.3), 0 4px 6px -4px rgb(0 0 0 / 0.3);
}

.generic__buttons-container {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: stretch;
  gap: 14px;
}

.generic__buttons:hover {
  background-color: var(--button-hover-color);
}

.generic__label {
  display: block;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
}

.generic__input {
  background-color: var(--light-bg-color);
  min-height: 32px;
  border-radius: 4px;
  padding: 4px;
  color: var(--text-color);
  line-height: 1.25;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2);
}

.generic__input-disabledBg:disabled {
  background-color: var(--main-bg-color);
  box-shadow: none;
  width: fit-content;
}

.generic__input:focus {
  background-color: var(--very-light-bg-color);
  border-color: var(--text-color);
}

.generic__Sidebar {
  background-color: var(--main-bg-color);
  overflow-y: auto;
  max-height: calc(100vh - 220px);
  border-radius: 0.25rem;
}

.generic__main-form {
  padding: 12px;
  position: relative;
  min-height: 100%;
}

.generic__main-form::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-image: url(../img/d20.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70vh;
  opacity: 0.05;
}

.main__container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  z-index: 40;
}

.generic__infoIcon {
  height: 17px;
  align-self: center;
  margin-left: 3px;
}

@media (min-width: 768px) {
  .generic__main-form {
    padding-left: 80px;
    padding-top: 48px;
    max-width: 896px;
  }
  .main__container {
    height: 95vh;
    max-height: 95vh;
  }
  .DraftEditor-root {
    min-height: 160px;
  }
  .generic__buttons {
    width: 160px;
    height: 40px;
  }
  .generic__buttons-container {
    gap: 80px;
  }
}

@media (min-width: 1400px) {
  .generic__main-form {
    padding-left: 120px;
    padding-top: 48px;
    max-width: 1100px;
  }
  .generic__input {
    min-height: 34px;
  }

  .generic__buttons {
    font-size: medium;
  }

  :root {
    font-size: large;
  }
}
