.Accordion__text {
  display: flex;
  align-items: center;
}
.Accordion_chevron {
  height: 14px;
  width: 14px;
}

.Accordion__title {
  color: var(--text-color);
  background-color: var(--main-bg-color);
  display: grid;
  grid-template-columns: min-content auto;
  gap: 8px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.8rem;
}

.Accordion__content {
  color: var(--text-color);
  background-color: var(--main-bg-color);
}

.Accordion__img {
  height: 36px;
  justify-self: center;
}

.Accordion__item {
  background-color: var(--very-light-bg-color);
  white-space: nowrap;
  font-size: small;
  padding: 4px 0;
  padding-left: 12px;
  cursor: pointer;
  height: 26px;
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-template-rows: 18px;
  align-items: center;
  overflow: hidden;
  margin: 2px 0;
}

.Accordion__item:hover {
  background-color: var(--light-bg-color);
}

.Accordion__selected {
  background-color: var(--light-bg-color);
}

@media (min-width: 1400px) {
  .Accordion_chevron {
    height: 18px;
    width: 18px;
  }
  .Accordion__title {
    font-size: 14px;
  }
  .Accordion__item {
    font-size: 14px;
  }
  .Accordion__img {
    height: 40px;
  }
}
