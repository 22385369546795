.ChaLocInfo__top-container {
  display: grid;
  grid-template-columns: 100%;
  gap: 8px;
}

.ChaLocInfo__img-container {
  position: relative;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.ChaLocInfo__img {
  height: auto;
  width: 100%;
  transition: all 100ms ease-in;
  transition-property: max-height, max-width;
}

.ChaLocInfo__genericImg {
  min-height: 200px;
  min-width: 200px;
}

.ChaLocInfo__img-zoom {
  position: absolute;
  padding: 6px;
  background-color: var(--button-color);
  width: 30px;
  height: 30px;
  bottom: -5px;
  right: -5px;
  display: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ChaLocInfo__bot-container {
  padding-bottom: 16px;
}
.ChaLoc__input {
  margin-right: 40px;
  width: 270px;
}

.ChaLocInfo__input-large {
  width: 100%;
  height: 128px;
  resize: none;
}

.ChaLocInfo__input-container {
  padding-top: 8px;
  height: fit-content;
}

@media (min-width: 768px) {
  .ChaLocInfo__top-container {
    grid-template-columns: min-content auto;
    gap: 24px;
  }
  .ChaLocInfo__big-img-container {
    grid-template-columns: 100%;
    gap: 0px;
  }
  .ChaLocInfo__img-container {
    min-width: 100px;
    min-height: 200px;
    justify-content: start;
  }
  .ChaLocInfo__input-large {
    height: 160px;
  }
  .ChaLocInfo__data-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 48px;
    align-content: center;
  }
  .ChaLocInfo__img-zoom {
    display: flex;
  }

  .ChaLocInfo__big-img {
    height: auto;
    width: 100%;
    max-width: 2000px;
    max-height: 3000px;
    transition: all 100ms ease-in;
    transition-property: max-height, max-width;
  }

  .ChaLocInfo__img {
    border-radius: 4px;
    object-fit: contain;
    height: 200px;
    max-height: 200px;
    width: auto;
    max-width: 600px;
  }
}

@media (min-width: 1400px) {
  .ChaLocInfo__img-container {
    min-width: 130px;
    min-height: 230px;
  }
  .ChaLoc__input {
    margin-right: 75px;
    width: 310px;
  }
  .ChaLocInfo__genericImg {
    min-height: 230px;
    min-width: 230px;
  }
  .ChaLocInfo__input-large {
    height: 175px;
  }
  .ChaLocInfo__img {
    object-fit: contain;
    height: 230px;
    max-height: 230px;
    width: auto;
    max-width: 600px;
  }
}

.ImgPopup__outer-container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImgPopup__container {
  background-color: var(--light-bg-color);
  width: 350px;
  height: 140px;
}

.ImgPopup__form {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;
}

.ImgPopup__top {
  padding: 16px;
}
.ImgPopup__bot {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 40px;
}
.ImgPopup__label {
  display: block;
  font-weight: 700;
  padding-bottom: 16px;
}

.ImgPopup__input {
  background-color: var(--very-light-bg-color);
  font-size: small;
  min-height: 28px;
  border-radius: 4px;
  padding: 4px;
  color: var(--text-color);
  line-height: 1.25;
  width: 100%;
}

.ImgPopup__button {
  background-color: var(--button-color);
  color: var(--very-light-bg-color);
}

.ImgPopup__button:hover {
  background-color: var(--button-hover-color);
  color: var(--very-light-bg-color);
}
