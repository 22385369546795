.JournalInfo__delete-alert-container {
  height: -150px;
  width: -350px;
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.JournalInfo__delete-alert-text-container {
  height: 100px;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}

.JournalInfo__delete-alert-button-container {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.JournalCard__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
  color: var(--text-color);
  font-size: 0.8rem;
  line-height: 1rem;
}
.JournalCard__text {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}

.JournalCard__container:hover {
  background-color: var(--light-bg-color);
}
.JournalInfo__input-container {
}
