.StatsInfo__container {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 12px;
  background-color: var(--main-bg-color);
  flex-grow: 1;
  position: relative;
  z-index: 10;
}

.StatsInfo__element-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.StatsInfo__element-container::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  /* background-color: red; */
  z-index: -1;
  background-image: url(../img/d20.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70vh;
  opacity: 0.05;
}

.StatsInfo__graph-container {
  margin: 16px 8px;
  background-color: var(--light-bg-color);
  overflow: auto;
  width: fit-content;
  max-width: 100%;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2);
}
.StatsInfo__Piechart-container {
  width: fit-content;
  max-width: 100%;
  overflow: auto;
}

.Stats__label {
  width: auto;
}

.StatsInfo__stat-container {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  gap: 8px;
}

.StatsTime__weekdays {
  background-color: var(--light-bg-color);
  border-radius: 4px;
  width: fit-content;
  padding: 8px;
  margin: 8px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  gap: 12px;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2);
}

.StatsLevelling__levels {
  background-color: var(--light-bg-color);
  width: fit-content;
  max-width: calc(100% - 16px);
  padding: 8px;
  margin: 8px;
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: min-content;
  grid-auto-columns: min-content;
  gap: 12px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2);
}

.StatsLevelling__levels-level {
  display: grid;
  grid-template-columns: 1fr 100px;
  white-space: nowrap;
  gap: 8px;
}

.Stats__sidebar-item {
  color: var(--text-color);
  font-weight: normal;
  background-color: var(--very-light-bg-color);
  padding: 8px;
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
}

.Stats__sidebar-item:hover {
  background-color: var(--light-bg-color);
}

.Stats__selected {
  background-color: var(--light-bg-color);
}

@media (min-width: 768px) {
  .StatsInfo__element-container {
    padding-left: 96px;
    padding-top: 48px;
    padding-right: 48px;
  }
  .StatsInfo__element-container::after {
    left: 96px;
    max-width: 752px;
  }
  .StatsInfo__stat-container {
    display: flex;
    margin-bottom: 8px;
  }
  .StatsLevelling__levels {
    grid-auto-flow: column;
  }
}
.StatsInfo__weekdays-day {
  display: grid;
  grid-template-columns: 100px 100px;
}

@media (min-width: 1400px) {
  .StatsInfo__element-container {
    padding-left: 120px;
    padding-right: 120px;
  }
  .StatsInfo__element-container::after {
    left: 120px;
    max-width: 868px;
  }
}
