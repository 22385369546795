.CampaignInfo__nested-lable {
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
}

.CampaignInfo__nested-table {
  background-color: var(--light-bg-color);
  margin: 8px;
  padding: 8px;
  width: fit-content;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.2);
}
